import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../layout/index.vue'
// 获取原型对象push函数
const originalPush = VueRouter.prototype.push

// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace

// 修改原型对象中的push函数
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch((err) => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/index',
  },
  {
    path: '/index',
    component: Layout,
    children: [
      // {
      //   path: '/',
      //   component: () =>
      //     import(/* webpackChunkName: "Assist" */ '../views/home/index.vue'),
      // },
      {
        path: '/',
        component: () =>
          import(/* webpackChunkName: "Assist" */ '../views/TabIndex.vue'),
      },
      // {
      //   path: 'history',
      //   component: () =>
      //     import(/* webpackChunkName: "Assist" */ '../views/history/index.vue'),
      // },
      {
        path: 'assist',
        component: () =>
          import(/* webpackChunkName: "Assist" */ '../views/Assist.vue'),
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
