//导入 IMLib 5.X
import * as RongIMLib from '@rongcloud/imlib-next'

import { Message, MessageBox } from 'element-ui'

// 导入 RTCLib、CallLib
import {
  installer as rtcInstaller,
  RCRTCClient,
  RCTrack,
  RCFrameRate,
  RCResolution,
  RCRTCCode,
  RCLocalTrack,
  device,
} from '@rongcloud/plugin-rtc'

// import {
//   installer as callInstaller,
//   // RCCallClient,
//   // RCCallSession,
//   RCCallErrorCode,
//   // IDeviceChangeParams,
//   // ISessionListener,
//   // IEndSummary,
//   // ISenderInfo,
//   // IMuteUser,
//   // IInvitedUsers,
//   // RCCallLanguage,
//   // RCCallEndReason,
//   // RCCallMediaType,
//   // IOfflineRecord,
//   // RCCallSessionState,
// } from '@rongcloud/plugin-call'

export const Messages = {
  MarkMessage: null, // 标记消息
  PigMessage: null, // 选猪消息
}

export default class RongClient {
  // 单例
  static getInstance(data) {
    if (!RongClient.instance) {
      RongClient.instance = new RongClient(data)
    }

    return RongClient.instance
  }
  constructor(config) {
    this.room = null
    this.rtcClient = null
    this.caller = null
    this.session = null
    this.sessionListener = null
    this.eventsListener = null
    this.roomListener = null
    this._init(config)
  }

  _init(config) {
    // 检查参数
    if (!config || !config.appKey) {
      console.error('参数中缺少app-key')
    }

    // 初始化
    RongIMLib.init({
      appkey: config.appKey,
      logOutputLevel: RongIMLib.LogL.RECORD,
    })
    // 初始化client
    const rtcClient = (this.rtcClient = RongIMLib.installPlugin(rtcInstaller))

    // 初始化caller
    // this.caller = RongIMLib.installPlugin(callInstaller, {
    //   // rtcClient 实例 （必填）
    //   rtcClient: rtcClient,
    //   /**
    //    * 被动收到邀请 （收到一个远端发起的新会话）, 会产生一个新的 session 对象 （必填）
    //    */
    //   onSession: (session, extra) => {
    //     console.log(session, extra, 'rongClient=>onsession')
    //     this.session = session
    //     let sessionListener = this.sessionListener
    //     if (sessionListener && sessionListener.onSessionStart) {
    //       sessionListener.onSessionStart(extra)
    //     }
    //     /**
    //      * **收到新的 session 后需要立即注册事件监听**
    //      */
    //     if (sessionListener) {
    //       session.registerSessionListener({
    //         ...sessionListener,
    //       })
    //     }
    //   },

    //   /**
    //    *  以下三条只要满足一条，就会触发onSessionClose
    //    *  1、本端用户自己主动挂断
    //    *  2、服务端把本端用户踢出 RTC 房间
    //    *  3、房间里小于2个人
    //    *
    //    *  @param {RCCallSession} session 被结束的 session 对象
    //    *  @param summaryInfo 结束一个 session 的后汇总信息
    //    */
    //   onSessionClose(session, summaryInfo) {
    //     let sessionListener = this.sessionListener
    //     if (sessionListener && sessionListener.onSessionClose) {
    //       sessionListener.onSessionClose(session, summaryInfo)
    //     }
    //   },
    // })

    // 添加事件监听
    const Events = RongIMLib.Events

    RongIMLib.addEventListener(Events.CONNECTING, () => {
      console.log('正在链接服务器')
    })

    RongIMLib.addEventListener(Events.CONNECTED, () => {
      console.log('已经链接到服务器')
    })

    RongIMLib.addEventListener(Events.DISCONNECT, () => {
      console.log('IM已断开服务器')

      // Message.error('您的账号在其他地方登录')
      MessageBox.confirm('您的账号在其他地方登录', '提示', {
        confirmButtonText: '是',
        // cancelButtonText: '否',
      })
        .then(() => {
          let url = 'https://zxzz.t.nxin.com/'
          window.parent.location.href = url
          next()
        })
        .catch(() => {})
    })

    RongIMLib.addEventListener(Events.MESSAGES, (evt) => {
      console.log('会议消息:', evt)
      const eventsListener = this.eventsListener
      if (eventsListener && eventsListener.onMessage) {
        eventsListener.onMessage(evt)
      }
    })

    Messages.MarkMessage = RongIMLib.registerMessageType(
      'AL:MARK',
      true,
      true,
      [],
      false
    )
    Messages.PigMessage = RongIMLib.registerMessageType(
      'AL:PIG',
      true,
      true,
      [],
      false
    )
  }

  // getCaller() {
  //   return this.caller
  // }
  getSession() {
    return this.session
  }
  getImLib() {
    return RongIMLib
  }
  /**
   * 获取群组人员列表
   * @returns
   */
  getRemoteUsers() {
    return this.session.getRemoteUsers()
  }
  // 链接融云server
  connect(userToken) {
    RongIMLib.connect(userToken).then((res) => {
      if (res.code === 0) {
        console.log('链接成功, 链接用户 id 为: ', res.data.userId)
      } else {
        console.warn('链接失败, code:', res.code)
      }
    })
  }

  // // 参数参照 https://doc.rongcloud.cn/call/Web/5.X/integration#init session.registerSessionListener
  registerSessionListener(listener) {
    this.sessionListener = listener
  }

  registerEventsListener(listener) {
    this.eventsListener = listener
  }

  // async callSinglePerson({ targetId, mediaType, listener }) {
  //   /**
  //    * 发起单人通话，如果成功后会产生一个新的session
  //    * @param targetId 被呼叫一方的用户 id   必填
  //    * @param mediaType 1->音频呼叫 or 2->音视频呼叫  必填
  //    * @param listener session对象上注册的事件 （必填）
  //    * @param constraints 获取音频或音视频资源时的参数 可选
  //    * @param params.channelId 组织 Id 可选
  //    */

  //   const { code, session } = await this.caller.callSinglePerson({
  //     targetId,
  //     mediaType,
  //     listener,
  //   })

  //   this.session = session
  //   console.log('rongclient=>callSinglePerson')
  //   // 返回 是否call成功
  //   return code === RCCallErrorCode.SUCCESS
  // }
  /**
   * 群组呼叫
   * @param {*} param0
   * @returns
   */
  // async callInGroup({ targetId, userIds, mediaType, listener, extra }) {
  //   /**
  //    * 发起单人通话，如果成功后会产生一个新的session
  //    * @param targetId 被呼叫一方的用户 id   必填
  //    * @param mediaType 1->音频呼叫 or 2->音视频呼叫  必填
  //    * @param listener session对象上注册的事件 （必填）
  //    * @param constraints 获取音频或音视频资源时的参数 可选
  //    * @param params.channelId 组织 Id 可选
  //    */
  //   const { code, session } = await this.caller.callInGroup({
  //     targetId,
  //     userIds,
  //     mediaType,
  //     listener,
  //     extra,
  //   })

  //   this.session = session
  //   console.log('rongclient=>callSinglePerson')
  //   // 返回 是否call成功
  //   return code === RCCallErrorCode.SUCCESS
  // }
  /**
   * 群组中呼叫单个用户
   * @param {*} params
   */
  // async sessionInvite({ userIds, options }) {
  //   const { code } = await this.session.invite(userIds, options)
  //   if (code === RCCallErrorCode.SUCCESS) {
  //     console.log('rongclient=>sessionInvite')
  //     // do something
  //   }
  // }
  // 接听
  // async sessionAccept() {
  //   const { code } = await this.session.accept()
  //   console.log('rongclient=>sessionAccept')
  //   // 返回 是否接通
  //   return code === RCCallErrorCode.SUCCESS
  // }
  //挂断
  // async sessionHungup() {
  //   const { code } = await this.session.hungup()
  //   console.log('rongclient=>sessionHungup')
  //   // 返回 是否接通
  //   return code === RCCallErrorCode.SUCCESS
  // }

  // 发送消息
  async sendMessage(targetId, messageBody, type = 'text') {
    // 指定消息发送的目标会话
    const conversation = {
      // targetId
      targetId,
      // 会话类型：RongIMLib.ConversationType.PRIVATE | RongIMLib.ConversationType.GROUP
      conversationType: RongIMLib.ConversationType.CHATROOM,
    }

    // 构建文本消息
    let message
    if (type === 'text') {
      // {
      //   content:''
      // }
      message = new RongIMLib.TextMessage(messageBody)
    } else if (type === 'image') {
      // {
      //   content: '', // 图片缩略图，应为 Base64 字符串，且不可超过 80KB
      //   imageUri: '' // 图片的远程访问地址
      // }
      message = new RongIMLib.ImageMessage(messageBody)
    } else if (type === 'file') {
      // {
      //   name: '',
      //   size: 1000,
      //   type: '',
      //   fileUrl: ''
      // }
      message = new RongIMLib.FileMessage(messageBody)
    } else if (type === 'video') {
      // {
      //   sightUrl: "<视频资源的远程地址>",
      //   content: "<缩略图base64>"
      //   duration: 10,
      //   size: 100,
      //   name: "视频名称"
      // }

      message = new RongIMLib.SightMessage(messageBody)
    }

    // 发送消息
    const { code, data } = await RongIMLib.sendMessage(conversation, message)
    return code === 0
  }

  // 发送消息
  async sendCustomMessage(targetId, message) {
    // 指定消息发送的目标会话
    const conversation = {
      // targetId
      targetId,
      // 会话类型：RongIMLib.ConversationType.PRIVATE | RongIMLib.ConversationType.GROUP
      conversationType: RongIMLib.ConversationType.CHATROOM,
    }

    console.log(conversation, message)
    // 发送消息
    const { code, data } = await RongIMLib.sendMessage(conversation, message)
    return code === 0
  }
  /**
   * 加入聊天室
   * @param {*} chatRoomId
   */
  joinChatRoom(chatRoomId) {
    const count = 50
    return new Promise((resolve, reject) => {
      RongIMLib.joinChatRoom(chatRoomId, {
        count: count,
      })
        .then((res) => {
          console.log(res, '加入聊天室成功')
          // 加入聊天室成功
          resolve(true)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  }
  /**
   *获取聊天室信息
   * @param {*} chatRoomId
   */
  getChatRoomInfo(chatRoomId) {
    RongIMLib.getChatRoomInfo(chatRoomId, {
      count: 20,
      order: 1,
    }).then((res) => {
      // 获取聊天室信息成功
      if (res.code === 0) {
        console.log(res.data)
        return res.data
      } else {
        console.log(res.code, res.data)
      }
    })
  }
  quitChatRoom(chatRoomId) {
    RongIMLib.quitChatRoom(chatRoomId).then((res) => {
      // 退出聊天室成功
      if (res.code === 0) {
        console.log('退出聊天室 ' + chatRoomId)
        return true
      }
    })
  }
}
export class RtcClient {
  static getInstance() {
    if (!RtcClient.instance) {
      RtcClient.instance = new RtcClient()
    }

    return RtcClient.instance
  }
  constructor(config) {
    this.room = null
    this.rtcClient = null
    this.userIds = null
    this.tracks = null
    // this.session = null
    // this.sessionListener = null
    // this.eventsListener = null
    this.roomListener = null
    this._init()
  }
  _init() {
    const rtcClient = (this.rtcClient = RongIMLib.installPlugin(rtcInstaller))
    return rtcClient
  }
  /**
   * rtc加入房间
   * @param {*} roomId
   * @returns
   */
  joinRTCRoom(roomId) {
    return new Promise(async (resolve, reject) => {
      // 加入普通音视频房间，从 5.0.7 开始增加返回 `tracks` 与 `userIds`
      // * userIds - 当前已加入房间的远端人员列表
      // * tracks  - 当前已发布至房间内的远端资源列表
      const { code, room, userIds, tracks } = await this.rtcClient.joinRTCRoom(
        roomId
      )
      this.room = room
      this.userIds = userIds
      this.tracks = tracks

      if (code == RCRTCCode.SUCCESS) {
        resolve(true)
      }
      // 若加入失败，则 room、userIds、tracks 值为 undefined
      if (code !== RCRTCCode.SUCCESS) {
        console.log('join living room failed:', code)
        resolve(false)
      }
    })
  }
  /**
   * 退出房间
   * @param {*} room
   */
  async leaveRoom(room) {
    // room 为加入房间方法返回的实例对象
    const { code } = await this.rtcClient.leaveRoom(room)
  }
  /**
   * 存储EventListener信息
   * @param {*} listener
   */
  registerRoomEventListener(listener) {
    this.roomListener = listener
  }
  /**
   * 注册rtc房间事件监听器
   */
  roomEventListener() {
    // 注册房间事件监听器，重复注册时，仅最后一次注册有效
    this.room.registerRoomEventListener(this.roomListener)
    console.log('注册RTC房间事件监听器成功')
  }
  /**
   * 资源订阅
   * 房间内其他用户新发布资源时触发
   * 如需获取加入房间之前房间内某个用户发布的资源列表，可使用 room.getRemoteTracksByUserId('userId') 获取
   * @param {*} tracks 新发布的音轨与视轨数据列表，包含新发布的 RCRemoteAudioTrack 与 RCRemoteVideoTrack 实例
   */
  async subscribe(track) {
    const { code } = await this.room.subscribe(track)
    console.log(code)
    if (code !== RCRTCCode.SUCCESS) {
      console.log('资源订阅失败 ->', code)
    }
  }
  /**
   * 取消订阅
   * @param {*} audioTrack
   * @param {*} videoTrack
   */
  async unsubscribe(audioTrack, videoTrack) {
    const { code } = await this.room.unsubscribe([audioTrack, videoTrack])
    console.log(code, 'unsubscribe')
  }
  /**
   *获取RTCClient
   * @returns
   */
  getRtcClient() {
    return this.rtcClient
  }
  /**
   * 获取房间Id
   * @returns
   */
  // getRoomId() {
  //   return this.room.getRoomId()
  // }
  getTracks() {
    return this.tracks
  }
  getRoom() {
    return this.room
  }
  getRCRTCCode() {
    return RCRTCCode
  }
  getRCLocalTrack() {
    return RCLocalTrack
  }
  getDevice() {
    return device
  }
  /**
   * 获取房间内已存在的远端用户列表
   * @returns
   */
  getRemoteUserIds() {
    const userIds = this.room.getRemoteUserIds()
    return userIds
  }

  //获取远端资源

  /**
   * 获取已发布资源列表
   * @returns 资源列表
   */
  getLocalTracks() {
    const tracks = this.room.getLocalTracks()
    return tracks
  }
  /**
   * 获取所有已发布的音视频资源列表
   * @returns
   */
  getRemoteTracks() {
    // 获取所有已发布的音视频资源列表 - 5.0.7 以上版本有效
    const remoteTracks = this.room.getRemoteTracks()
    return remoteTracks
  }
  /**
   * 获取指定远端用户的音视频资源列表
   * @param {String} someUserId
   * @returns
   */
  getRemoteTracksByUserId(someUserId) {
    const remoteTracks = this.room.getRemoteTracksByUserId(someUserId)
    return remoteTracks
  }

  //本地音视频资源获取

  /**
   * 麦克风捕获音频流，获取并创建音频轨道
   * @param {String} tag 资源标识
   * @param {Object} options 音频配置项
   * @returns
   */
  async audioTrack(tag, options) {
    /**
     * @description 仅当 `code === RCRTCCode.SUCCESS` 时 audioTrack 有值,
     * audioTrack 为 RCMicphoneAudioTrack 类型实例
     * @param tag 资源标识，不传时默认为 RongCloudRTC，代表浏览器麦克风资源，
     * 也可传入其他包含 A-Z、a-z、0-9、+、=、- 的字符串，
     * @param IMicphoneAudioProfile 音频配置项，可选参数
     * @param IMicphoneAudioProfile.micphoneId 指定麦克风设备 Id
     * @param IMicphoneAudioProfile.sampleRate 指定音频采样率
     */
    const { code, track } = await this.rtcClient.createMicrophoneAudioTrack(
      tag,
      options
    )
    return track
  }
  /**
   * 摄像头捕获视频流，获取并创建视频轨道
   * @param {String} tag
   * @param {Object} options
   * @returns
   */
  async videoTrack(tag, options) {
    /**
     * @description 仅当 `code === RCRTCCode.SUCCESS` 时 videoTrack 有值
     * videoTrack 为 RCCameraVideoTrack 类型实例
     * @param tag 资源标识，不传时默认为 RongCloudRTC，代表浏览器摄像头资源，
     * 也可传入其他包含 A-Z、a-z、0-9、+、=、- 的字符串，
     * @param ICameraVideoProfile 视频配置项，可选参数
     * @param ICameraVideoProfile.cameraId 指定摄像头设备 Id
     * @param ICameraVideoProfile.frameRate 指定视频帧率，默认为 RCFrameRate.FPS_15
     * @param ICameraVideoProfile.resolution 指定视频分辨率，默认为 RCResolution.W640_H480
     */
    const { code, track } = await this.rtcClient.createCameraVideoTrack(
      tag,
      options
    )
    return track
  }
  /**
   * 同时获取音视频流
   * @param {*} tag
   * @param {*} options
   * @returns
   */
  async microphoneAndCameraTracks(tag, options) {
    /**
     * @description tracks 是一个数组，当 `code !== RCRTCCode.SUCCESS` 时，tracks 长度为 0
     * @param tag 资源标识，不传时默认为 RongCloudRTC，代表浏览器摄像头、麦克风资源，
     * 也可传入其他包含 A-Z、a-z、0-9、+、=、- 的字符串，
     * @param options 音视频配置项，可参考上述 1、2 中的介绍
     */
    const { code, tracks } =
      await this.rtcClient.createMicrophoneAndCameraTracks(tag, options)

    if (code === RCRTCCode.SUCCESS) {
      // tracks 包含一个 RCMicphoneAudioTrack 实例和一个 RCCameraVideoTrack 实例
      const [audioTrack, videoTrack] = tracks
      return tracks
    }
  }
  /**
   * 根据 MediaStream 实例对象创建 RCLocalTrack 实例
   * @param {*} tag 轨道标识
   * @param {*} stream MediaStream 实例
   * @param {*} options
   * @returns
   */
  async createLocalTracks(tag, stream, options) {
    /**
     * 根据 MediaStream 实例对象创建 RCLocalTrack 实例
     * @param tag 轨道标识
     * @param stream MediaStream 实例
     * @param options 可用于指定 `withoutVideo` 与 `withoutAudio` 以剔除视轨与音轨
     */
    const { code, tracks } = await this.rtcClient.createLocalTracks(
      tag,
      stream,
      options
    )
    return tracks
  }

  // 发布资源取消资源

  /**
   *发布资源
   * @param {*} audioTrack
   * @param {*} videoTrack
   */
  async publish(audioTrack, videoTrack) {
    const { code } = await this.room.publish([
      audioTrack,
      // {
      //   track: videoTrack,
      //   pubTiny: true, // pubTiny 用于指定同时发布视频数据的同时，额外发布一个小流数据
      // },
    ])
    if (code == RCRTCCode.SUCCESS) {
      console.log('资源发布成功')
    }

    // 若资源发布失败
    if (code !== RCRTCCode.SUCCESS) {
      console.log('资源发布失败:', code)
    }
  }
  /**
   * 取消发布
   * @param {*} audioTrack
   * @param {*} videoTrack
   */
  async unpublish(audioTrack, videoTrack) {
    const { code } = await this.room.unpublish([audioTrack, videoTrack])

    if (code !== RCRTCCode.SUCCESS) {
      console.log('取消发布失败:', code)
    }

    // 取消发布后，业务层若不再需要播放资源，可调 destroy 方法销毁资源
    // audioTrack.destroy()
    // videoTrack.destroy()
  }
}
