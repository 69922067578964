const defaultColors = {
  main: '#009DFF', //主色
  secondary: '#00C8FF', // 辅色
  tips: '#FFDE00', // 提示色
  border: '#03315F', // 边框色
  titleBg: '#282EFF', // 标题背景
  componentBg: '#140F31', // 组件背景色
  dialogBg: '#070822', // 弹窗背景色
  font1: '#CED4FF', // 字体颜色1
  font2: '#FFFFFF', // 字体颜色2
}

// var a = encodeURIComponent(JSON.stringify(defaultColors))

// 检查localStorage中是否有缓存
// const localColorStr = localStorage.getItem('localColors')
// let localColors = {}
// if (localColorStr) {
//   localColors = JSON.parse(localColorStr)
// }

// 检查url中是否有color参数
const urlObj = new URL(location.href)
const queryColorStr = urlObj.searchParams.get('color')
let queryColors = {}
if (queryColorStr) {
  queryColors = JSON.parse(queryColorStr)
}

// todo 处理query中的颜色参数

const colors = {
  ...defaultColors,
  // ...localColors,
  ...queryColors,
}

// localStorage.setItem('localColors', JSON.stringify(colors))

const convertAlphaTo16 = (alpha) => {
  return Math.ceil(alpha * 255).toString(16)
}

const variables = `
  :root{
    --al-main-bg-color:rgba(5,2,26,1);
    --al-main-color:${colors.main};
    --al-main-01-color:${colors.main}${convertAlphaTo16(0.1)};
    --al-main-02-color:${colors.main}${convertAlphaTo16(0.2)};
    --al-main-03-color:${colors.main}${convertAlphaTo16(0.3)};
    --al-secondary-color:${colors.secondary};
    --al-tips-color:${colors.tips};
    --al-border-color:${colors.border};
    --al-title-bg-color:${colors.titleBg};
    --al-title-bg-03-color:${colors.titleBg}${convertAlphaTo16(0.3)};
    --al-component-bg-color:${colors.componentBg};
    --al-component-bg-01-color:${colors.componentBg}${convertAlphaTo16(0.1)};
    --al-component-bg-03-color:${colors.componentBg}${convertAlphaTo16(0.3)};
    --al-component-bg-05-color:${colors.componentBg}${convertAlphaTo16(0.5)};
    --al-dialog-bg-color:${colors.dialogBg};
    --al-text-color1:${colors.font1};
    --al-text-01-color1:${colors.font1}${convertAlphaTo16(0.1)};
    --al-text-03-color1:${colors.font1}${convertAlphaTo16(0.3)};
    --al-text-05-color1:${colors.font1}${convertAlphaTo16(0.5)};
    --al-text-color2:${colors.font2};
  }
`
var style = document.createElement('style')
style.type = 'text/css'
style.innerHTML = variables
document.head.appendChild(style)
