<template>
  <transition name="el-fade-in-linear">
    <div class="call-wrap" v-if="openCallMark">
      <div class="listeners">
        <div class="header">
          <div class="icon">
            <img :src="avatar" alt="" />
          </div>
          <span>{{ targetUser.userName }}</span>
        </div>
        <div class="footer">
          <!-- <el-button :loading="loading" v-show="loading">连接中···</el-button> -->
          <div class="answer" v-if="showAcceptBtn" @click="handleAccept">
            <!-- 接听 -->
            <img src="../assets/img/call.png" alt="" />
          </div>
          <div class="hangUp" v-if="showHungupBtn" @click="handleHungup">
            <!-- 挂断 -->
            <img src="../assets/img/call-ended.png" alt="" />
          </div>
          <div class="hangUp" v-if="showCancelBtn" @click="handleCancel">
            <img src="../assets/img/call-ended.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import avatar from '../assets/img/avatar.jpg'
import { customCmd } from '../api/index'
export default {
  name: 'CallPage',
  data() {
    return {
      avatar: avatar,

      // showCallBtn: true,
      // showAcceptBtn: false,
      // showHungupBtn: false,
    }
  },
  computed: {
    openCallMark() {
      return this.$store.state.callPageVisible
    },
    showAcceptBtn() {
      return this.$store.state.showAccept
    },
    showHungupBtn() {
      return this.$store.state.showHungup
    },
    showCancelBtn() {
      return this.$store.state.showCancel
    },
    targetUser() {
      return this.$store.state.targetUser
    },
    targetRoom() {
      return this.$store.state.targetRoom
    },
    user() {
      return this.$store.state.user
    },
  },
  mounted() {},
  methods: {
    handleCall() {},

    async handleAccept() {
      let result = await this.cmdCall(2)
      if (result.success) {
        this.callPageSetStatus(false, false, false, false)
        //接通电话后
        //保存房间信息
        this.$store.commit('TYPE_WHETHERJOINROOM', true)
        this.$store.commit('TYPE_ROOMINFO', result.data)
        this.$router.push('/index/assist')
        //im加入聊天室
        // this.joinChatRoom(res.data.roomId)
      }
    },
    async handleHungup() {
      let result = await this.cmdCall(1)
      if (result.success) {
        this.callPageSetStatus(false, false, false, false)
      }
    },
    async handleCancel() {
      let result = await this.cmdCall(3)
      if (result.success) {
        this.callPageSetStatus(false, false, false, false)
      }
    },
    /**
     * 自定义信令呼叫
     * @param {Boolean} type
     */
    cmdCall(type) {
      return new Promise((resolve, reject) => {
        let data = {
          token: this.user.userId,
          callType: type,
          openCamera: true,
          roomId: this.targetRoom.roomId,
          toUserId: this.targetUser.userId,
        }
        customCmd(data)
          .then((res) => {
            if (res.success) {
              resolve(res)
            }
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    callPageSetStatus(pageType, acceptType, hungupType, cancelType) {
      this.$store.commit('TYPE_CALLPAGEVISIBLE', pageType)
      this.$store.commit('TYPE_ACCEPT', acceptType)
      this.$store.commit('TYPE_HUNGUP', hungupType)
      this.$store.commit('TYPE_CANCEL', cancelType)
    },
  },
}
</script>
<style lang="scss" scoped>
// .call-wrap {
//   width: 300px;
//   height: 400px;
//   position: fixed;
//   top: 50%;
//   left: 50%;
//   transform: translate3d(-50%, -50%, 0);
//   z-index: 2;
//   border: 1px solid #ccc;
//   border-radius: 5px;
//   background: white;
// }
// .name {
//   height: 40px;
//   text-align: center;
//   line-height: 40px;
//   margin-top: 50px;
// }
// .avatar {
//   height: 80px;
//   line-height: 80px;
//   text-align: center;
// }
.call-wrap {
  width: 100%;
  height: 100%;
  background: rgba(2, 2, 2, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;
  .listeners {
    width: 300px;
    height: 350px;
    background: #fff;
    border-radius: 5px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    display: flex;
    flex-direction: column;
    .header {
      .icon {
        width: 80px;
        height: 80px;

        margin: 50px auto 0;
        text-align: center;
        line-height: 80px;

        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
      span {
        display: block;
        text-align: center;
        line-height: 40px;
      }
      .helpStyle {
        width: 100%;
        height: 40px;
        line-height: 40px;
        text-align: center;
        font-size: 16px;
        font-weight: 600;
      }
    }
    .footer {
      height: 40px;
      width: 100%;
      position: absolute;
      bottom: 50px;
      left: 0;
      display: flex;
      justify-content: space-around;
      .answer {
        width: 60px;
        height: 60px;
        line-height: 60px;
        background-color: rgb(81, 175, 53);
        border-radius: 50%;
        cursor: pointer;
      }
      .hangUp {
        width: 60px;
        height: 60px;
        line-height: 60px;
        background-color: rgb(201, 83, 79);
        border-radius: 50%;
        cursor: pointer;
      }
      .answer,
      .hangUp {
        display: flex;
        align-items: center;
        justify-content: center;
        user-select: none;
        img {
          width: 40px;
          height: 40px;
        }
      }
    }
  }
}
</style>
