<template>
  <div id="app">
    <CallPage />
    <router-view v-if="loaded" />
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import RongClient, { RtcClient } from './thirdParty/RongClient'
import {
  mediaToken,
  contactList,
  currentUser,
  customCmd,
  refreshToken,
} from './api/index'
import Emitter from './thirdParty/Emitter'
import CallPage from './components/callPage'
export default {
  components: {
    CallPage: CallPage,
  },
  data() {
    return {
      // appKey:
      //   process.env.NODE_ENV == 'production'
      //     ? 'pvxdm17jpup4r'
      //     : '3argexb63pz4e', //al
      appKey: '3argexb63pz4e', //al
      // appKey: 'pvxdm17jpup4r', // nx
      rongToken: '',
      user: '',
      userList: [],
      loaded:false
    }
  },
  computed: {
    roomId() {
      return this.$store.state.roomInfo.roomId
    },
  },
  created() {
    this.userInit()
  },
  mounted() {
    this.rongInit()
  },
  methods: {
    rongInit() {
      console.log('初始化im')
      //初始化rongIM
      this.$store.commit(
        'TYPE_RONGCLIENT',
        RongClient.getInstance({
          appKey: this.appKey,
        })
      )
      this.$store.commit('TYPE_RTCCLIENT', new RtcClient())
    },
    async userInit() {
      let NODE_ENV = process.env.NODE_ENV == 'production'
      var url = new URL(location.href)
      let tokenObj
      // 检查url中是否包含uid和compId参数
      const uid = url.searchParams.get('uid')
      const compId = url.searchParams.get('compId')
      if (!uid) {
        this.$message.error('请携带uid')
        return
      }
      if (!compId) {
        this.$message.error('请携带compId')
        return
      }
      // if (NODE_ENV) {
      // 刷新用户token
      tokenObj = await refreshToken({ userId: uid })
      this.$store.commit('TYPE_TOKENTYPE', tokenObj.data)
      this.$store.commit('TYPE_ISTOKENBACKTYPE', true)
      // 获取初始化融云用到的token
      const tokenRes = await mediaToken({
        token: tokenObj.token,
        compId: compId,
      })
      this.$store.commit('TYPE_MEDIATOKEN', tokenRes.data)
      this.rongToken = tokenRes.data
      this.handleConnect()
      this.getCurrentUser(tokenObj.token)
      this.loaded = true
    },
    getCurrentUser(token) {
      let data = {
        token,
      }
      currentUser(data).then((res) => {
        if (res.success) {
          //当前登录用户信息
          console.log('信息回来')
          this.$store.commit('TYPE_USER', {
            userId: res.data.uid,
            userName: res.data.realName,
          })
        }
      })
    },
    /**
     * 获取通讯列表做message消息体数据
     */
    async getContactList() {
      await contactList({ token: this.user, linkName: '' }).then((res) => {
        this.userList = res.data
      })
    },
    handleConnect() {
      // 设置链接融云监听的事件函数
      const eventListener = {
        onMessage: async (evt) => {
          await this.getContactList()
          // let msg = evt.messages[0]
          evt.messages.forEach((msg) => {
            // 接收到呼叫消息
            if (msg.messageType == 'AL:CALL') {
              //callType 呼叫类型  0 呼叫 1 挂断 2 接听 3 取消 4 繁忙
              if (msg.content.content.callType == 0) {
                if (this.roomId) {
                  this.call(msg.targetId, 4)
                }
                this.$store.commit('TYPE_TARGEUSER', {
                  userId: msg.content.content.user.uid,
                  userName: msg.content.content.user.realName,
                })
                this.$store.commit('TYPE_TARGEROOM', {
                  roomId: msg.content.content.roomId,
                  roomName: msg.content.content.roomName,
                })
                this.callPageSetStatus(true, true, true, false)
              } else if (msg.content.content.callType == 1) {
                this.$message.error(
                  `${msg.content.content.user.realName}拒绝邀请`
                )
                this.$store.commit('TYPE_TARGEUSER', {})
                this.$store.commit('TYPE_TARGEROOM', {})
                this.callPageSetStatus(false, false, false, false)
              } else if (msg.content.content.callType == 2) {
                this.$message.success(
                  `${msg.content.content.user.realName}加入会议`
                )
                this.$store.commit('TYPE_TARGEUSER', {})
                this.$store.commit('TYPE_TARGEROOM', {})
                this.callPageSetStatus(false, false, false, false)
              } else if (msg.content.content.callType == 3) {
                this.$message.error(
                  `${msg.content.content.user.realName}取消会议邀请`
                )
                this.$store.commit('TYPE_TARGEUSER', {})
                this.$store.commit('TYPE_TARGEROOM', {})
                this.callPageSetStatus(false, false, false, false)
              } else if (msg.content.content.callType == 4) {
                this.$message.error(
                  `${msg.content.content.user.realName}正在与会中`
                )
                this.$store.commit('TYPE_TARGEUSER', {})
                this.$store.commit('TYPE_TARGEROOM', {})
                setTimeout(() => {
                  this.callPageSetStatus(false, false, false, false)
                }, 1000)
              }
            } else if (msg.messageType == 'RC:TxtMsg') {
              // 文本消息
              let senderUser = {}
              //其他用户发送消息的响应，在此处理用户消息并存储store
              this.setMsg({
                type: 'text',
                content: msg.content.content,
                user: msg.content.user,
              })
            } else if (msg.messageType == 'RC:ImgMsg') {
              // 图片消息
              console.log(msg.content)
              this.setMsg({
                type: 'img',
                imageUri: msg.content.imageUri,
                user: msg.content.user,
              })
            } else if (msg.messageType == 'RC:FileMsg') {
              // 文件消息
              this.setMsg({
                type: 'file',
                fileUrl: msg.content.fileUrl,
                name: msg.content.name,
                fileType: msg.content.type,
                size: msg.content.size,
                user: msg.content.user,
              })
            } else if (msg.messageType == 'AL:PIG') {
              // 处理选猪消息
              // 离线消息不处理
              if (msg.isOffLineMessage) return
              // 处理选猪
              // 超过5s 丢掉
              if (Date.now() - msg.content.sendTime > 5000) {
                return
              }
              Emitter.emit('selectPig', msg.content)
            } else if (msg.messageType == 'AL:MARK') {
              // 处理标记消息
              // 离线以及超时消息都会丢掉
              if (msg.isOffLineMessage) return
            
              if (Date.now() - msg.sentTime > 5000) {
                console.log('拒绝消息')
                return
              }
              // 处理标记
              Emitter.emit('drawMark', msg.content)
            }
          })
        },
      }
      this.$store.state.rongClient.registerEventsListener(eventListener)
      // 融云链接
      this.$store.state.rongClient.connect(this.rongToken)
    },
    /**
     * 设置呼叫组件显示
     * @param {Boolean} pageType 呼叫页
     * @param {Boolean} hungupType 挂断
     * @param {Boolean} acceptType 接听
     */
    callPageSetStatus(pageType, hungupType, acceptType, cancelType) {
      this.$store.commit('TYPE_CALLPAGEVISIBLE', pageType)
      this.$store.commit('TYPE_HUNGUP', hungupType)
      this.$store.commit('TYPE_ACCEPT', acceptType)
      this.$store.commit('TYPE_CANCEL', cancelType)
    },
    call(targetId, type) {
      let data = {
        token: this.user,
        callType: type,
        openCamera: true,
        roomId: this.roomId,
        toUserId: targetId,
      }
      customCmd(data).then((res) => {
        if (res.success) {
          this.$store.commit('TYPE_TARGEUSER', {})
          this.$store.commit('TYPE_TARGEROOM', {})
          this.$store.commit('TYPE_CALLPAGEVISIBLE', false)
          this.$store.commit('TYPE_CANCEL', false)
        }
      })
    },
    /**
     * 消息处理存储
     * @param {Object} param type:消息类型 content:消息主体 user:用户信息
     */
    setMsg(param) {
      let { type, content, imageUri, fileUrl, name, fileType, size, user } =
        param
      let suffixType
      if (type == 'file') {
        var suffix = name.substring(name.lastIndexOf('.') + 1)
        if (suffix == 'docx') {
          suffixType = 'docx'
        } else if (suffix == 'xlsx') {
          suffixType = 'xlsx'
        } else if (suffix == 'pdf') {
          suffixType = 'pdf'
        } else if (suffix == 'txt') {
          suffixType = 'txt'
        }
      }
      let data = {
        data: {
          content: {
            content,
            imageUri,
            fileUrl,
            name,
            size,
            type: suffixType,
            user: {
              id: user.id,
              name: user.name,
              portrait: '',
            },
          },
        },
        type,
        position: user.id == this.$store.state.user.userId ? true : false,
      }
      let list = this.$store.state.messageList
      list.push(data)
      this.$store.commit('TYPE_MESSAGELIST', list)
    },
    ...mapActions(['init']),
  },
}
</script>
<style lang="scss">
#app {
  width: 100%;
  height: 100%;
}
body::-webkit-scrollbar {
  display: none;
}
</style>
