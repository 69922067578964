import Vue from 'vue'
import moment from 'moment'
import './utils/initCssVariable'

import App from './App.vue'
import router from './router'
import store from './store'
import './icons'

//elementUi引入全局
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
//初始化重置css样式
import './assets/css/reset.css'
import './assets/css/iconfont.css'
//rem适配
import './utils/lib-flexible'

import jwchat from 'jwchat'

Vue.config.productionTip = false

Vue.use(ElementUI)
Vue.use(jwchat)

Vue.prototype.$moment = moment

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
