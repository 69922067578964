<template>
  <el-container>
    <el-main>
      <nav>
        <div
          :class="{ 'nav-btn': true, active: activeNav === 'home' }"
          @click="toHome"
        >
          实时选猪
        </div>
        <div
          :class="{ 'nav-btn': true, active: activeNav === 'history' }"
          @click="toHistory"
        >
          选猪记录
        </div>
      </nav>
      <router-view />
    </el-main>
  </el-container>
</template>
<script>
export default {
  data() {
    return {
      activeNav: 'home',
    }
  },
  mounted() {
    if (location.href.indexOf('history') > -1) {
      this.activeNav = 'history'
    }
  },
  methods: {
    toHome() {
      this.activeNav = 'home'
      this.$router.push({ path: '/index' })
      this.$store.commit('TYPE_TABTYPE', true)
    },
    toHistory() {
      this.activeNav = 'history'
      // this.$router.push('/index/history')
      this.$router.push({ path: '/index' })
      this.$store.commit('TYPE_TABTYPE', false)
    },
  },
}
</script>
<style lang="scss" scoped>
.el-container {
  height: 100%;
}
.el-header {
  background-color: black;
  color: var(--al-text-color2);

  height: 60px;
  line-height: 60px;
  font-size: 24px;
}
.el-main {
  height: 100%;
  padding: 0;
}
.el-main::-webkit-scrollbar {
  display: none;
}
nav {
  position: absolute;
  z-index: 99;
  width: 192px;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 20px;
  display: flex;
  height: 47px;
  align-items: center;
  justify-content: space-between;
  .nav-btn {
    cursor: pointer;
    color: var(--al-text-color1);
    font-size: 16px;
    opacity: 0.5;
    &.active {
      border-bottom: 3px solid var(--al-secondary-color);
      opacity: 1;
    }
  }
}
</style>
