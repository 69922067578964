import Vue from 'vue'
import Vuex from 'vuex'

import { createRoom, joinRoom, getUserUnfinishedRoomInfo,contactList } from '../api'

Vue.use(Vuex)


const checkTrackStatus = (data,track,flag)=>{
  if(track._tag==="screenshare"){
    data.screen = flag
    data.screenType = flag
  }else if(track.isAudioTrack()){
    data.audio = true
    data.audioType = flag
  }else {
    data.video = true
    data.videoType = flag
  }

  console.log(data)

}

export default new Vuex.Store({
  state: {
    token: '',
    mediaToken: '',
    isTokenBackType: false, //token是否已兑换回来
    tabType: true,
    user: {
      userId: '',
      userName: '',
    }, //人员信息
    targetUser: {}, //来电方用户信息
    targetRoom: {}, //来电方房间信息
    rongClient: null, //rongclient信息
    rtcClient: null, //rtcClient信息
    whetherJoinRoom: false,
    roomInfo: {
      roomId: '',
      roomName: '',
    }, //房间信息
    microphoneStream: null, //用户麦克风媒体资源
    cameraStream: null, //用户摄像头媒体资源
    screenStream: null, //用户屏幕共享资源
    beforeMediaStream: null,
    mediaStreamList: [], //房间内媒体资源列表
    audioMediaStreamList: [], //音频资源
    mediaStreamMain: null, //视频切换主视频 视频源
    // layoutMediaStreamList: []true, //视频切换列表
    messageList: [], //消息集合
    memberList: [], //参会者列表

    callPageVisible: false, //呼叫组件是否可见
    showAccept: false, //接听
    showHungup: false, //挂断
    showCancel: false, //取消
    users:[], // 参与会议用户列表
    contacts:[]
  },
  mutations: {
    TYPE_TOKENTYPE: (state, action) => {
      state.token = action
    },
    TYPE_MEDIATOKEN: (state, action) => {
      state.mediaToken = action
    },
    TYPE_ISTOKENBACKTYPE: (state, action) => {
      state.isTokenBackType = action
    },

    TYPE_TABTYPE: (state, action) => {
      state.tabType = action
    },
    TYPE_USER: (state, action) => {
      state.user = action
    },
    TYPE_ROOMINFO: (state, action) => {
      state.roomInfo = action
    },
    TYPE_RONGCLIENT: (state, action) => {
      state.rongClient = action
    },
    TYPE_RTCCLIENT: (state, action) => {
      state.rtcClient = action
    },
    TYPE_MESSAGELIST: (state, action) => {
      state.messageList = action
    },
    TYPE_CALLPAGEVISIBLE: (state, action) => {
      state.callPageVisible = action
    },
    TYPE_ACCEPT: (state, action) => {
      state.showAccept = action
    },
    TYPE_HUNGUP: (state, action) => {
      state.showHungup = action
    },
    TYPE_CANCEL: (state, action) => {
      state.showCancel = action
    },
    TYPE_MEMBERLIST: (state, action) => {
      state.memberList = action
    },
    TYPE_TARGEUSER: (state, action) => {
      state.targetUser = action
    },
    TYPE_TARGEROOM: (state, action) => {
      state.targetRoom = action
    },
    TYPE_WHETHERJOINROOM: (state, action) => {
      state.whetherJoinRoom = action
    },
    TYPE_MEDIASTREAMLIST: (state, action) => {
      state.mediaStreamList = action
      if(state.mediaStreamMain){
        const hasTrack = action.some(item=>item._id===state.mediaStreamMain._id)
        if(!hasTrack){
          state.mediaStreamMain = null
        }
      }
    },
    TYPE_AUDIOMEDIASTREAMLIST: (state, action) => {
      state.audioMediaStreamList = action
    },

    TYPE_MICROPHONESTREAM: (state, action) => {
      state.microphoneStream = action
    },
    TYPE_CAMERASTREAM: (state, action) => {
      state.cameraStream = action
    },
    TYPE_SCREENSTREAM: (state, action) => {
      state.screenStream = action
    },
    TYPE_BEFOREMEDIASRTEAM: (state, action) => {
      state.beforeMediaStream = action
    },

    TYPE_MEDIASTREAMMAIN: (state, action) => {
      state.mediaStreamMain = action
    },
    save:(state, action)=>{
      Object.keys(action).forEach((key)=>{
        state[key] = action[key]
      })
    }
  },
  actions: {
    async init({ commit, state, dispatch }, {}) {
      // todo 获取用户是否有已经加入的会议
      const res = await getUserUnfinishedRoomInfo({
        // userId: state.user.userId,
      })
      return res
    },
    async createRoom({ commit, state, dispatch }, { roomName, token }) {
      let data = {
        token,
        roomName,
      }
      const res = await createRoom(data)
      if (res.success) {
        commit('TYPE_ROOMINFO', res.data)
      }
      return res
    },
    async joinRoom({ commit, state, dispatch }, { roomId, token }) {
      const res = await joinRoom({ token, roomId })
      if (res.success) {
        commit('TYPE_ROOMINFO', res.data)
      }
      return res.success
    },
    async syncUserStatus({ commit, state, dispatch }, {track,flag,mode,userIds}) {
      let users = state.users
      if(mode==='add'){
        users = users.concat(userIds.map(userId=>{
          return {
            audio:true,
            video:true,
            userId
          }
        }))
      }else if(mode==='delete'){
        users = users.filter(item=>{
          return !userIds.includes(item.userId)
        })
      }else{
        const tracks =  track instanceof Array?track:[track]
        tracks.filter(i=>i).forEach(item=>{
          console.log('aaaaaaa',item._userId)
          const userId = item._userId
          if(!userId) return
          let statusItem =users.find(user=>user.userId===userId)
          if(!statusItem){
            statusItem =  {
              audio:true,
              video:true,
              userId
            }
            users.push(statusItem)
          }
          checkTrackStatus(statusItem,item,flag)
      })
    }
      console.log(1111111,JSON.stringify(users))
      commit('save',{
        users:[...users]
      })
      dispatch('getContactList')
     
    },
    
    async getContactList({ commit, state }) {
      const res = await contactList({likeName:''})
      commit('save',{
        contacts:res.data
      })
    },
    
  },
  modules: {},
})
