export function isEmpty(data) {
  for (var key in data) {
    if (data[key] === '') {
      delete data[key]
    }
    if (data[key] === 'NaN-NaN-NaN') {
      delete data[key]
    }
    if (data[key] == null) {
      delete data[key]
    }
    if (data[key] == undefined) {
      delete data[key]
    }
  }
}
export default function treeIsEmpty(data) {
  for (var key in data) {
    if (isObjArr(data[key]) == 'Object') {
      deleteObj(data[key])
      treeIsEmpty(data[key])
    } else if (isObjArr(data[key]) == 'Array') {
      treeIsEmpty(data[key])
    }
  }
}
function deleteObj(data) {
  for (var key in data) {
    if (data[key] === '') {
      delete data[key]
    }
    if (data[key] === 'NaN-NaN-NaN') {
      delete data[key]
    }
    if (data[key] == null) {
      delete data[key]
    }
    if (data[key] == undefined) {
      delete data[key]
    }
  }
}
function isObjArr(value) {
  if (Object.prototype.toString.call(value) === '[object Array]') {
    return 'Array'
  } else if (Object.prototype.toString.call(value) === '[object Object]') {
    return 'Object'
  }
}
