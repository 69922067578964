import axios from 'axios'
import lodash from 'lodash'
import store from '../store/index'
// import router from '../router/index'
import { Message } from 'element-ui'
import treeIsEmpty, { isEmpty } from './isEmpty'
// const NODE_APP_HOST = 'https://zxzvr.t.nxin.com' //
const NODE_APP_HOST = location.origin
const NODE_ENV = process.env.NODE_ENV == 'production'
const service = axios.create({
  baseURL: '/',
  timeout: 10000,
})
export let http = {}
// 添加请求拦截器
service.interceptors.request.use(
  (config) => {
    if (store.state.token) {
      config.headers.Authorization = `${store.state.token}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)
service.interceptors.response.use(
  (response) => {
    let rsp = response.data
    if (rsp.success != true) {
      var patt = /export/
      if (!patt.test(response.config.url)) {
        Message.error(rsp.message)
      }
    }
    return response
  },
  (error) => {
    let response = error.response
    if (response.status != 401) {
      Message.error(response.data.message)
    }
    if (response.status === 401 && !response.config.refresh_token) {
      // router.push({ path: '/login' })
    }
    return Promise.reject(response)
  }
)

/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */
http.get = function (params) {
  let { url, queryData } = params
  return new Promise((resolve, reject) => {
    isEmpty(queryData)
    service
      .get(url, {
        params: queryData,
      })
      .then((response) => {
        resolve(response.data)
      })
      .catch((err) => {
        if (err.status != 401) {
          reject(err.data)
        }
      })
  })
}
/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */
http.getFile = function (params) {
  let { url, queryData } = params
  return new Promise((resolve, reject) => {
    isEmpty(queryData)
    service
      .get(url)
      .then((response) => {
        const link = document.createElement('a')
        link.href = `${NODE_APP_HOST}${url}`
        link.style.display = 'none'
        link.setAttribute('target', '_blank')
        // link.download = '用户列表.xlsx'
        document.body.appendChild(link)
        link.click()
        link.remove()
        // resolve(response.data)
      })
      .catch((err) => {
        if (err.status != 401) {
          reject(err.data)
        }
      })
  })
}
/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */
http.post = function (params) {
  let { url, queryData, time = 6000 } = params
  isEmpty(queryData)
  let formData = new FormData()
  const objChangeMap = (obj) => {
    let map = new Map()
    for (let key in obj) {
      map.set(key, obj[key])
    }
    return map
  }
  let map = objChangeMap(queryData)
  map.forEach((val, key) => {
    formData.set(key, val)
  })
  return new Promise((resolve, reject) => {
    service({
      method: 'post',
      url: url,
      data: formData,
      timeout: time,
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((err) => {
        console.log(err)
        if (err.status != 401) {
          reject(err.data)
        }
      })
  })
}

http.postList = function (url, data = {}) {
  treeIsEmpty(data)
  let formData = new FormData()
  const objChangeMap = (obj) => {
    let map = new Map()
    for (let key in obj) {
      map.set(key, obj[key])
    }
    return map
  }
  const tree = (data) => {
    data.forEach((val, key) => {
      if (val instanceof Array) {
        val.forEach((j, i) => {
          objChangeMap(j).forEach((v, k) => {
            formData.set(`${key}[${i}].${k}`, v)
          })
        })
      } else if (typeof val == 'object') {
        objChangeMap(val).forEach((v, k) => {
          formData.set(`${key}.${k}`, v)
        })
      } else {
        formData.set(`${key}`, val)
      }
    })
    return formData
  }
  let map = objChangeMap(data)
  tree(map)
  return new Promise((resolve, reject) => {
    service({
      method: 'post',
      url: url,
      data: formData,
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

/**
 * 封装delete方法
 * @param url
 * @param data
 * @returns {Promise}
 */
http.delete = function (url) {
  return new Promise((resolve, reject) => {
    service
      .delete(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((err) => {
        if (err.status != 401) {
          reject(err.data)
        }
      })
  })
}

// export const exportFile = (url) => {
//   window.open(`${NODE_APP_HOST}${url}`)
// }

export default service
