import lodash from 'lodash'
import { http } from '../utils/request'

/**
 * 获取融云用户token
 * @param {*} params
 * @returns
 */
export function mediaToken(params) {
  let url = '/acp/api/userRelation/mediaToken'
  return http.get({ url, queryData: params })
}

/**
 * uid获取token/刷新token
 * @param {*} params
 * @returns
 */
export function refreshToken(params) {
  let { userId } = params
  let url = '/acp/api/userRelation/refreshToken'
  return http.post({ url, queryData: { userId } })
}
/**
 * 创建/加入/邀请别人加入会议
 * @param {*} params
 * @returns
 */
export function joinRoom(params) {
  let { roomId } = params
  let url = '/acp/api/im/joinRoom'
  return http.post({ url, queryData: { roomId } })
}
/**
 * 创建房间(基于聊天室)
 * @param {*} params
 * @returns
 */
export function createRoom(params) {
  let { roomId, roomName } = params
  let url = '/acp/api/im/createRoom'
  return http.post({ url, queryData: { roomId, roomName } })
}
/**
 * 销毁房间(rtc)
 * @param {*} params
 * @returns
 */
export function destroyRoom(params) {
  let { roomId, roomName } = params
  let url = '/acp/api/im/destroyRoom'
  return http.post({ url, queryData: { roomId, roomName } })
}
export function isRoomOwner(params) {
  let { roomId, roomName } = params
  let url = '/acp/api/im/isRoomOwner'
  return http.post({ url, queryData: { roomId, roomName } })
}
/**
 * 获取房间内人员与资源
 * @param {*} params
 * @returns
 */
export function roomUserInfo(params) {
  let url = '/acp/api/im/roomInfo'
  let { roomId } = params
  return http.get({
    url,
    queryData: {
      roomId,
    },
  })
}
/**
 * 获取当前用户信息
 * @param {*} params
 * @returns
 */
export function currentUser(params) {
  // let { token } = params
  let url = '/acp/api/userRelation/currentUser'
  return http.get({ url })
}
export function getUserInfo(params) {
  let { userId } = params
  let url = `/acp/api/userRelation/${userId}`
  return http.get({
    url,
  })
}
/**
 * 自定义信令-单聊通道
 * @param {*} params
 */
export function customCmd(params) {
  let { callType, openCamera, roomId, toUserId } = params
  let url = '/acp/api/im/customCmd'
  return http.post({
    url,
    queryData: { callType, openCamera, roomId, toUserId },
  })
}
/**
 * 创建/加入/邀请别人加入会议
 * @param {*} params
 * @returns
 */
export function contactList(params) {
  let { likeName } = params
  let url = '/acp/api//userRelation/contactList'
  return http.get({ url, queryData: { likeName } })
}

/**
 * 实时会议
 * @param {*} query
 * @returns
 */
export const getListInProgress = (query) => {
  let url = '/acp/api/videoRecord/queryListPage/progress'
  return http.get({ url, queryData: query })
}

/**
 * 选猪记录
 * @param {*} query
 * @returns
 */
export const getListFinished = (query) => {
  let url = '/acp/api/videoRecord/queryListPage/finished'
  return http.get({ url, queryData: query })
}

/**
 * 获取未完成会话的roomId
 * @param {*} query
 * @returns
 */
export const getUserUnfinishedRoomInfo = (params) => {
  // let { token } = params
  let url = '/acp/api/im/unfinishedRoomInfo'
  return http.get({ url })
}

/**
 * 获取猪只列表
 * @param {*} query
 * @returns
 */
export const getPigList = (query) => {
  let url = '/acp/api/pig/queryList'
  return http.get({ url, queryData: query })
}

/**
 * 获取猪只详情
 * @param {*} query
 * @returns
 */
export const getPigInfo = (query) => {
  let url = '/acp/api/pig/info'
  return http.get({ url, queryData: query })
}

/**
 * 删除猪只
 * @param {*} query
 * @returns
 */
export const deletePig = (query) => {
  let url = '/acp/api/pig/delete'
  return http.post({ url, queryData: query })
}

/**
 * 选中猪只
 * @param {*} query
 * @returns
 */
export const choosePig = (query) => {
  let url = '/acp/api/pig/choose'
  return http.post({ url, queryData: query })
}

/**
 * 放弃猪只
 * @param {*} query
 * @returns
 */
export const rejectPig = (query) => {
  let url = '/acp/api/pig/reject'
  return http.post({ url, queryData: query })
}

/**
 * 获取猪只详情
 * @param {*} query
 * @returns
 */
export const pigExport = (query) => {
  let { id } = query
  let url = `/acp/api/videoRecord/export/${id}`
  return http.getFile({ url })
}

export const queryRoomMembers = async (roomId) => {
  let data = {
    roomId,
  }

  let users = []
  const roomUserInfoRes = await roomUserInfo(data)

  if (roomUserInfoRes.success) {
    const members = roomUserInfoRes.data.members || []
    const userInfoPromise = async (info) => {
      const userRes = await getUserInfo({ userId: info.userId })

      const userObj = {
        name: userRes.data.username,
        id: userRes.data.userId,
      }

      const uris = lodash.get(info, 'data.uris', [])
      uris.forEach((k, j) => {
        //用户是否发布audio/video
        //发布后是否关闭有效， state 0为关闭 1为开启
        if (k.mediaType == 0) {
          userObj.audio = true
          if (k.state == 1) {
            userObj.audioType = true
          } else {
            userObj.audioType = false
          }
        } else if (k.mediaType == 1) {
          if (k.tag == 'RongCloudRTC' || k.tag ==  'USB') {
            userObj.video = true
            if (k.state == 1) {
              userObj.videoType = true
            } else {
              userObj.videoType = false
            }
          } else if (k.tag == 'screenshare') {
            userObj.screen = true
            if (k.state == 1) {
              userObj.screenType = true
            } else {
              userObj.screenType = false
            }
          }
        }
      })
      return userObj
    }

    users = await Promise.all(members.map((info) => userInfoPromise(info)))
  }

  console.log(users)

  return users
}
